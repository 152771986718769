<template>
  <div class="wrap">
    <label class="search" for="search">
      <img class="searchicon" src="./../assets/ui/magGlass.svg" alt="search">
      <input :id="`browseSearchInput${type}`" v-model="search" v-on:keyup.esc="clearSearch" :placeholder="serchType">
      <transition name="fade">
        <div v-if="search.length > 0">
          <!-- <transition name="zoom"> -->
            <div>
              <p class="cross" @click="clearSearch">X</p>
              <p class="esc">esc</p>
            </div>
          <!-- </transition> -->
        </div>
      </transition>

    </label>
  </div>
</template>

<script>
export default {
  name: 'searchinput',
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      search: '',
    };
  },
  computed: {
    serchType() {
      return `Search ${this.type}`;
    },
  },
  watch: {
    search(search) {
      this.$emit('search', search);
    },
  },
  methods: {
    clearSearch() {
      this.search = '';
      // scroll to top when esc as this is anew search
      document.getElementById(`browseSearchInput${this.type}`).blur();
      document.getElementById(`browseSearchInput${this.type}`).focus();
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  justify-content: center;
}
.search {
  display: flex;
  width: 400px;
  margin: auto;
  background: lighten(#03053b, 5%);
  background: lighten(#3F5F69, 5%);
  background-image: linear-gradient(-90deg, #39535F 0%, #405E68 100%);

  border: 1px solid lighten(#03053b, 15%);
  border: 5px solid #2a3e44;
  box-shadow: #c9bf92 0 0 0 5px;

  border-radius: 20px;
  margin: 20px;
  padding: 24px 30px 24px 30px;
  transition: .25s;

  position: relative;

  &:focus-within {
    background: lighten(#2a3e44, 10%);
    background-image: linear-gradient(-90deg, rgb(77, 111, 128) 0%, rgb(75, 109, 121) 100%);
    border: 5px solid lighten(#2a3e44, 10%);
    // border: 5px solid lighten(#8C866C, 20%);
    .esc {
      opacity: 0.5;
    }
  }

  > .searchicon {
    margin: 0;
    height: 42px;
    width: 42px;
    //old svg -> stlyes
    // height: 34px;
    // width: 34px;

  }

  > input {
    margin-left: 20px;
    background: none;
    border: none;
    outline: none;
    color: #fff6e1;
    text-shadow: 1px 1px 0 rgba(#000000, 0.3);
    width: 240px;
    font-size: 30px;
    caret-color: #ede688;
    &::placeholder {
      //color: lighten(#3F5F69, 15%);
      color: rgba(#c9bf92, 0.6);
      text-shadow: 0 0 0 rgba(#000000, 0.3);
      margin-left: 18px;
    }
  }

  > div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    // width: 30px;
    > div {
      transform: scale(1,1);
    
      > p {
        text-align: center;
        margin: 0 auto;

      }
      .cross{
        font-weight: 400;
        padding: 14px 8px 2px;
        font-size: 28px;
        transition: 0.25s;
        text-shadow: 1px 1px 0 rgba(#000000, 0.3);

        &:hover {
          color: #f0c944;
          transform: scale(1.2);
          text-shadow: 1px 1px 1px rgba(#000000, 0.3);

        }
      }
      .esc {
        text-transform: uppercase;
        font-size: 12px;
        opacity: 0;
        // display:none;
      }
    }
  }
}

.fade-enter-active {
  transition: all 50ms ease-in;
  // transition: bounce 200ms ;
}
.fade-leave-active {
  transition: all 150ms linear;
  opacity: 1;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


// .zoom-enter-active {
//   animation: zoom .5s;
// }
// .zoom-leave-active {
//   animation: zoom .5s reverse;
// }

// // .zoom-enter, .zoom-leave-to {
// //   transform: scale(0,0);
// // }

// @keyframes zoom {
//   0% {
//     transform: scale(0);
//   }
//   50% {
//     transform: scale(1.2);
//   }
//   100% {
//     transform: scale(1);
//   }
// }
</style>

