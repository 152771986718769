<template>
  <div class="browse">
    <div class="margin"></div>
    <div class="jumplinks">
      <div class="gap"></div>
      <div class="wrap">
        <div class="head">Jump To</div>
        <div class="link" @click="focusSearch('browseSearchInputcards');">Cards</div>
        <div class="link" @click="focusSearch('browseSearchInputrelics');">Relics</div>
      </div>
    </div>
    <Adsense
      v-show="!prerenderHideElement"
      class="ga"
      data-ad-client="ca-pub-5393590966220416"
      data-ad-slot="3351730842"
      data-ad-format="auto">
    </Adsense>
    <RunsArea v-if="this.$route.name === 'sls-home'"/>
    <cards
      @addCardInfo="addCardToDeck"
      :character="character"
      v-if="!prerenderHideElement"
    ></cards>
    <relics
      @addRelicInfo="addRelicToDeck"
      v-if="!prerenderHideElement && ready"
    ></relics>
    <!-- <div class="cardscompared" v-if="cardsCompared > 0 && !prerenderHideElement">
      <div class="number">{{cardsCompared}}</div>
      <div class="text">Cards Compared</div>
    </div> -->

  </div>
</template>

<script>
import cards from './cards';
import relics from './relics';
import search from './searchinput';
import RunsArea from './RunsArea';
// import itemdetail from './itemdetail';

export default {
  name: 'loadout',
  components: { cards, relics, search, RunsArea },
  props: {
    character: {
      type: String,
    }
  },
  data(){
    return {
      prerenderHideElement: false, // this is to hide show prerendered blocks
      ready: false,
      // cardsCompared: 0,
    }
  },
  mounted(){
    // Prerender function
    if (typeof window.__PRERENDER_INJECTED !== "undefined") {
      this.prerenderHideElement = window.__PRERENDER_INJECTED.prerenderHide
    }
    setTimeout(() => {
      this.ready = true;
    }, 1000);

    // this.totalComparisons()
  },
  methods: {
    addCardToDeck(name, plus) {
      this.$emit('addCardInfo', name, plus);
    },
    addRelicToDeck(name) {
      this.$emit('addRelicInfo', name);
    },
    focusSearch(selector) {
      document.getElementById(selector).focus();
    },
    // async totalComparisons() {
    //   try {
    //     const res = await this.$api.post(
    //       '/', {
    //         query: `
    //           query {
    //             comparisonsTotal {
    //               comparisonsTotal
    //             }
    //           }
    //         `,
    //     })
    //     console.log(res.data.data);
    //     this.cardsCompared = res.data.data.comparisonsTotal.comparisonsTotal
    //   } catch (e) {
    //     console.log('err', e)
    //   }
    // }

  }
};
</script>

<style lang="scss" scoped>
.browse {
  padding: 50px 0;
  padding: 0; // fix for weird sticky jump button hover on loadout browse
  background: rgba(#10212B, 0.85);
  background: rgba(#132a36, 0.95);
  background: rgba(#132a36, 1);
  box-shadow: rgba(#c9bf92, 1) 0 -6px 0 0;
  border-top: 5px solid #364f57;
  min-height: 1000px;
  overflow-y: auto;
  // position: relative;
}
.margin { // fix for sticky padding issue
  margin-top: 50px;
}
.marginprerender { // fix for sticky padding issue
  margin-top: 500px;
}
.jumplinks {
  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: block;
  margin: 0 0 -240px 24px;
  // left: 30px;
  width: 80px;
  padding: 0;
  text-align: center;
  > .gap {
    height: 130px;

  }
  > .wrap {
    position: relative;
    background: #364f57;
    border-radius: 4px;
    overflow: hidden;

    > .head { 
      padding: 12px;
      border-bottom: 1px solid #3b565f;
      background: #2b3f46;
      &:hover {
        cursor: default;
      }
    }
    > .link {
      padding: 8px 12px;
      &:hover {
        background: #4b6d79;
        cursor: pointer;
      }
    }
  }
}
.cardscompared {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 140px;
  > .number {
    font-size: 56px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  > .text {
    font-size: 18px;
    text-transform: uppercase;
  }
}

.ga {
  margin: 0 auto 40px;
  text-align: center;
}
</style>
