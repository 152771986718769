



















































































import gql from 'graphql-tag';
import Run from '../../../graphql/RunFragment.gql';

import VueNumber from 'vue-number-animation';
Vue.use(VueNumber);

import Vue from 'vue';
export default Vue.extend({
  name: 'runsArea',
  data() {
    return {
      totalRuns: 0,
      spirespyGetTotalRunCount: 0,
      spirespyGetLastXRuns: 0,
      spirespyGetLast20Asc20Runs: [],
      userSpireSpyXHighestUploadCount: [],
      fromCount: 0,
    };
  },
  apollo: {
    // Query with parameters
    spirespyGetTotalRunCount: {
      // gql query
      query: gql`query {
        spirespyGetTotalRunCount
      }`,
      // Static parameters
      // variables() {
      //   return {
      //     owner: this.itemName,
      //   }
      // },
      // update: (data) => data.totalruns,
    },
    spirespyGetLast20Asc20Runs: {
      // gql query
      query: gql`query($count: Int) {
        spirespyGetLastXAsc20Runs(count: $count) {
          ... Run
        }
      },
      ${Run}`,
      // Static parameters
      variables() {
        return {
          count: 20,
        };
      },
      update: (data) => data.spirespyGetLastXAsc20Runs,
    },
    spirespyGetLastXRuns: {
      // gql query
      query: gql`query($count: Int) {
        spirespyGetLastXRuns(count: $count) {
          ... Run
        }
      },
      ${Run}`,
      // Static parameters
      variables() {
        return {
          count: 20,
        };
      },
      update: (data) => data.spirespyGetLastXRuns,
    },
    userSpireSpyXHighestUploadCount: {
      // gql query
      query: gql`query($count: Int!) {
        userSpireSpyXHighestUploadCount(count: $count) {
          id
          username
          runcount
        }
      }`,
      // Static parameters
      variables() {
        return {
          count: 20,
        };
      },
      update: (data) => data.userSpireSpyXHighestUploadCount,
    },
  },

  // computed: {
  //   newToCount(): number {
  //   },
  // },
  watch: {
    spirespyGetTotalRunCount(): number {
      this.updateFromCount();
      return this.spirespyGetTotalRunCount;
    },
  },
  methods: {
    updateFromCount(): void {
      this.fromCount = this.spirespyGetTotalRunCount;
    },
    victoryOrSlain(bool: boolean): string {
      if (bool) {
        return 'victory';
      }
      return 'slain';
    }
  },

  mounted() {
    setInterval(() => {
      console.log('gettotalruns');
      this.$apollo.queries.spirespyGetTotalRunCount.refetch();
    }, 150000);
  },
});
