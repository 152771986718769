<template>
  <div class="cards">
    <!-- <input type="text" v-model="search"> -->
    <search
      @search="searchData"
      type="cards"
    ></search>
    <transition name="fade">
      <div v-if="character !== undefined" class="search-filters" :class="{ 'search-filters-active': search.length > 0 }">
        <div class="filter" @click.prevent="allCharacterSearch = !allCharacterSearch">
          <div class="tickbox">
            <img src="./../assets/ui/toggleButtonBase.png" alt="">
            <img class="check" v-if="allCharacterSearch" src="./../assets/ui/toggleButtonOverlay2.png" alt="">
          </div>
          <input class="input" type="checkbox" name="addotherchamps" id="addotherchamps" v-model="allCharacterSearch">
          <label class="label" for="addotherchamps">All characters</label>
        </div>
      </div>
    </transition>
    <div v-if="group !== 'search' || (group === 'search' && search !== '')" v-for="(group, index) in cardGroupsOrder" :key="`index-${group}`" :id="`card-${group}`" class="card-type-block">
      <template v-if="search === ''">
        <h2 name="ironclad">{{group}}</h2>
        <h3>{{cardTotals[group]}} Cards</h3>
        <!-- <div>
          <p style="text-align:left;">Filter</p>
        </div> -->
      </template>

      <!-- <img class="h2underline" src="./../assets/ui/underline.svg" alt=""> -->
      <ul>
        <!-- <li>
          <img src="./../assets/cards/R-bash.png" alt="">
        </li> -->

        <!-- correct from here -->
        <li v-show="search !== ''" v-if="group === 'search' && search.length > 0" v-for="(card, indexSearch) in searchFilter" :key="indexSearch" :id="`card-${index}`" @click="openItemModal(card.name)">
            <card
              :character="card.character"
              :title="card.name"
              :type="card.type"
              :mana="card.mana"
              :manaplus="card.manaplus"
              :rarity="card.rarity"
              :tier="card.tier"
              :description="card.description"
              :descriptionplus="card.descriptionplus"
              :beta="card.beta"
              @addCardInfo="addCardToDeck"
              :showTimeIndex="indexSearch"
            ></card>
        </li>

        <li v-show="search === ''" v-if="group === 'defect'" v-for="(card, index) in defect" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>
        <li v-show="search === ''" v-if="group === 'ironclad'" v-for="(card, index) in ironclad" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>
        <li v-show="search === ''" v-if="group === 'silent'" v-for="(card, index) in silent" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>
        <li v-show="search === ''" v-if="group === 'watcher'" v-for="(card, index) in watcher" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>
        <li v-show="search === ''" v-if="group === 'colorless'" v-for="(card, index) in colorless" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>
        <li v-show="search === ''" v-if="group === 'curse'" v-for="(card, index) in curse" :key="index" :id="`card-${index}`" @click="openItemModal(card.name)">
          <card
            :character="card.character"
            :title="card.name"
            :type="card.type"
            :mana="card.mana"
            :manaplus="card.manaplus"
            :rarity="card.rarity"
            :tier="card.tier"
            :description="card.description"
            :descriptionplus="card.descriptionplus"
            :beta="card.beta"
            @addCardInfo="addCardToDeck"
            :showTimeIndex="index"
          ></card>
        </li>

      </ul>


    </div>

    <!-- <h2>Silent</h2>
    <h3>{{cardTotals.silent}} / {{silent.length}} Cards</h3>
    <ul>
      <li v-for="(card, index) in silent" :key="index" :id="`card-${index}`">
        <card
          :character="card.character"
          :title="card.name"
          :type="card.type"
          :mana="card.mana"
          :manaplus="card.manaplus"
          :rarity="card.rarity"
          :description="card.description"
          :descriptionplus="card.descriptionplus"
          level="test"
          titleFontSize=""
          :highlightDesc="false"
        ></card>
      </li>
    </ul>
    <h2>Colorless</h2>
    <h3>{{cardTotals.colorless}} / {{colorless.length}}</h3>
    <ul>
      <li v-for="(card, index) in colorless" :key="index" :id="`card-${index}`">
        <card
          :character="card.character"
          :title="card.name"
          :type="card.type"
          :mana="card.mana"
          :manaplus="card.manaplus"
          :rarity="card.rarity"
          :description="card.description"
          :descriptionplus="card.descriptionplus"
          level="test"
          titleFontSize=""
          :highlightDesc="false"
        ></card>
      </li>
    </ul>
    <h2>Curse</h2>
    <h3>{{cardTotals.curse}} <span><span>/</span> {{curse.length}} </span> </h3>
    <ul>
      <li v-for="(card, index) in curse" :key="index" :id="`card-${index}`">
        <card
          :character="card.character"
          :title="card.name"
          :type="card.type"
          :mana="card.mana"
          :manaplus="card.manaplus"
          :rarity="card.rarity"
          :description="card.description"
          :descriptionplus="card.descriptionplus"
          level="test"
          titleFontSize=""
          :highlightDesc="false"
        ></card>
      </li>
    </ul> -->
    <transition name="modal">
      <!-- <div> -->
        <itemdetail
          class="card-modal"
          v-if="singleItemNameForModal !== null && singleItemNameForModal.length > 0"
          :itemName="singleItemNameForModal"
          :itemType="singleItemTypeForModal"
          :isModal="true"
          @closeCardModal="closeCardModal"
          @changeItemName="openItemModal"
        />
      <!-- </div> -->
    </transition>
  </div>
</template>

<script>
import card from './cardv2';
import allData from './carddata.json';
import search from './searchinput';
import itemdetail from './itemdetail';

export default {
  name: 'Cards',
  components: { card, search, itemdetail },
  props: {
    browser: {
      type: Boolean,
      default: true,
    },
    character: {
      type: String,
    },
  },
  data() {
    return {
      cardGroups: ['search','ironclad', 'silent', 'defect', 'watcher', 'colorless', 'curse'],
      allCharacters: ['ironclad','silent', 'defect', 'watcher'],
      otherCardGroups: ['colorless', 'curse'],
      cards: allData.cards,
      search: '',
      allCharacterSearch: false,
      singleItemNameForModal: '',
      singleItemTypeForModal: 'cards',
    };
  },
  computed: {
    searchRegex() {
      if (this.search.length > 0) {
        let regex = '';
        // console.log(this.search);
        // console.log(this.search.length);

        for (let s = 0; s < this.search.length; s++) {
          // console.log('loop'+ s);

          if (s === 0){
            regex += this.search.charAt(s);
          } else {
            regex += `[^${this.search.charAt(s)}]*${this.search.charAt(s)}`;
          }
        }
        regex += '.*'
        return regex;
      }
      return '';
    },
    searchFilter() {
      // console.log(this.searchRegex);
      const regexRules = new RegExp(this.searchRegex, 'gi')
      // console.log(regexRules.match('a'));
      const vue = this;
      if (this.search.length > 0){
        return this.cards.filter((card) => {
          if (card.name.match(regexRules)) {
            // console.log(card);
            // console.log(vue.character);

            // filter out other champ cards - true / false
            // show all cards where character is undefined (homepage)
            if (!vue.allCharacterSearch && vue.character !== undefined) {
              // console.log(vue.otherCardGroups);

              // show all cards in non-character groups (colorless..)
              for (let d = 0; d < vue.otherCardGroups.length; d++) {
                // console.log('test');
                if (card.character === vue.otherCardGroups[d]) {
                  // console.log(vue.otherCardGroups[d] + ' card');
                  return card;
                }
              }

              // show only this character cards
              if (card.character === vue.character){
                // console.log(card.name + ' is not the character ' + vue.character);
                return card;
              }
            } else {
              return card;
            }
          }
        });
      }
      return ''
    },
    ironclad() {
      return this.cards.filter((card) => {
        if (card.character === 'ironclad') {
          return card;
        }
      });
    },
    silent() {
      return this.cards.filter((card) => {
        if (card.character === 'silent') {
          return card;
        }
      });
    },
    defect() {
      return this.cards.filter((card) => {
        if (card.character === 'defect') {
          return card;
        }
      });
    },
    watcher() {
      return this.cards.filter((card) => {
        if (card.character === 'watcher') {
          return card;
        }
      });
    },
    colorless() {
      return this.cards.filter((card) => {
        if (card.character === 'colorless') {
          return card;
        }
      });
    },
    curse() {
      return this.cards.filter((card) => {
        if (card.character === 'curse') {
          return card;
        }
      });
    },
    cardTotals() {
      // for (let char = 0; char < this.cardGroups.length; char++)
      //   if (card.character === character) {
      //     return card;
      //   }

      // }
      const ironclad = this.cards.filter((card) => {
        if (card.character === 'ironclad') {
          return card;
        }
      });
      const silent = this.cards.filter((card) => {
        if (card.character === 'silent') {
          return card;
        }
      });
      const defect = this.cards.filter((card) => {
        if (card.character === 'defect') {
          return card;
        }
      });
      const watcher = this.cards.filter((card) => {
        if (card.character === 'watcher') {
          return card;
        }
      });
      const colorless = this.cards.filter((card) => {
        if (card.character === 'colorless') {
          return card;
        }
      });
      const curse = this.cards.filter((card) => {
        if (card.character === 'curse') {
          return card;
        }
      });
      return {
        ironclad: ironclad.length,
        silent: silent.length,
        defect: defect.length,
        watcher: watcher.length,
        colorless: colorless.length,
        curse: curse.length,
      };
    },
    cardGroupsOrder() {
      let groupOrder = this.otherCardGroups.slice(0);
      groupOrder.splice(0, 0, 'search');
      // if no character, list search, character, colorless, curse
      if (this.character === undefined) {
        for (let char = 0; char < this.allCharacters.length; char++) {
          groupOrder.splice((char + 1), 0, this.allCharacters[char]);
        }
        return groupOrder;
      }
      // else list other characters at the bottom
      groupOrder.splice(1, 0, this.character);
      const otherCharacters = this.allCharacters.filter((type) => type !== this.character);
      for (let char = 0; char < otherCharacters.length; char++) {
        groupOrder.push(otherCharacters[char]);
      }
      return groupOrder;
    },
  },
  methods: {
    addCardToDeck(name, plus) {
      this.$emit('addCardInfo', name, plus);
    },
    searchData(searchData) {
      this.search = searchData;
    },
    itemPureName: function(name) {
      return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase()
    },
    openItemModal(itemName, type = 'cards'){
      // console.log('open modal');
      if (this.$route.name === 'sls-home') {

        const name = this.itemPureName(itemName);
        this.singleItemNameForModal = name;
        this.singleItemTypeForModal = type;
      }
    },
    closeCardModal(){
      console.log('hi');

      this.singleItemNameForModal = '';
    }
  }
};
</script>

<style lang="scss" scoped>

.cards {
  max-width: 1280px;
  margin: auto;
  padding: 0 0 100px;
}

.card-type-block {
  padding: 30px 0 80px;
}

ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 25px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media screen and (min-width: 630px) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  @media screen and (min-width: 728px) {
    grid-gap: 30px;
    padding: 50px 50px;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
li {
  // width: 17.6%;
  width: 100%;
  // margin: 1% 1.2%;
  display: flex;
  align-items: flex-end;
  // overflow: hidden;
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  // transition: scale 0.5s easing's easeOutCubic curve;
  @media(hover: hover) {
    &:hover {
      transform: scale(1.28, 1.28);
      transition: 0s;
      z-index: 100;
      & ~ #sts-card-base-img {
        box-shadow: 0 0 20px 20px rgba(#000, 0.4);
      }
    // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
    }
  }
  
}

// @supports (display: grid) {
//   ul {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(200px 1fr));
//     grid-gap: 1em;
//   }
// }
h2 {
  margin: 0 60px;
  text-align: left;
  font-size: 40px;
  text-shadow: 3px 3px 0 #000;
  text-transform: capitalize;
}

h3 {
  margin: 14px 60px 0;
  text-align: left;
  font-size: 22px;
  text-shadow: 3px 3px 0 #000;
  font-weight: 700;
  > span {
    opacity: 0.7;
    > span {
      margin: 0 3px;
    }
  }
}

.h2underline {
  width: 80px;
  text-align: left;
  margin-left: 80px;
}

.alignleft {
  text-align: left;
}

.search-filters {
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  opacity: 0;
  user-select: none;

  > .filter {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    > .tickbox {
      position: relative;
      width: 32px;
      height: 32px;
      margin-right: 4px;
      .check {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
      }

    }
    > .label {
    }
    .input {
      position: absolute;
      top: -10000px;
    }
  }
  &-active {
    opacity: 1 !important;
  }
}

.card-modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}


// animations

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.card-animation-leave-active,
.card-animation-enter-active {
  transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card-animation-enter {
  transform: translateY(100%);
  // z-index: 3000;
}
.card-animation-leave-to {
  transform: translateY(0);
  // z-index: 2900;
}

.modal-enter-active, .modal-leave-active {
  transition: .3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateY(100vh)
}
// .modal-enter-active, .modal-leave-active {
//   transition: .3s;
// }
// .modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   opacity: 0;
//   transform: scale(0.96)
// }
</style>

