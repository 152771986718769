<template>
  <div class="relics">
    <!-- <input type="text" v-model="search"> -->
    <search
      @search="searchData"
      :inactive="true"
      type="relics"
    ></search>
    <ul>
      <!-- Search Results -->
      <li v-show="search !== ''" v-for="(relic, indexSearch) in searchFilter" :key="indexSearch" :id="`relic-${indexSearch}`" @click="openItemModal(relic.name)">
        <relic
          :character="relic.character"
          :title="relic.name"
          :rarity="relic.rarity"
          :description="relic.description"
          @addRelicInfo="addRelicToDeck">
        </relic>
      </li>

      <!-- Relics List -->
      <li
        v-show="search === ''"
        v-for="(relic, indexSearch) in relics"
        :key="indexSearch"
        :id="`relic-${indexSearch}`"
        @click="openItemModal(relic.name)"
      >
        <relic
          :character="relic.character"
          :title="relic.name"
          :rarity="relic.rarity"
          :description="relic.description"
          @addRelicInfo="addRelicToDeck">
        </relic>
      </li>
    </ul>
    <transition name="modal">
      <!-- <div  > -->
        <itemdetail
          class="relic-modal"
          v-if="singleItemNameForModal !== null && singleItemNameForModal.length > 0"
          :itemName="singleItemNameForModal"
          :itemType="singleItemTypeForModal"
          :isModal="true"
          @closeCardModal="closeCardModal"
          @changeItemName="openItemModal"
        />
      <!-- </div> -->
    </transition>
  </div>
</template>

<script>
import relic from './relic';
import allData from './carddata.json';
import search from './searchinput';
import itemdetail from './itemdetail';

export default {
  name: 'Relics',
  components: { relic, search, itemdetail },
  props: {
    browser: {
      type: Boolean,
      default: true,
    },
    character: {
      type: String,
    },
  },
  data() {
    return {
      relics: allData.relics,
      search: '',
      allCharacterSearch: false,
      singleItemNameForModal: '',
      singleItemTypeForModal: 'relics',
    };
  },
  computed: {
    searchRegex() {
      if (this.search.length > 0) {
        let regex = '';
        // console.log(this.search);
        // console.log(this.search.length);

        for (let s = 0; s < this.search.length; s++) {
          // console.log('loop' + s);
          if (s === 0) {
            regex += this.search.charAt(s);
          } else {
            regex += `[^${this.search.charAt(s)}]*${this.search.charAt(s)}`;
          }
        }
        regex += '.*';
        return regex;
      }
      return '';
    },
    searchFilter() {
      // console.log(this.searchRegex);
      const regexRules = new RegExp(this.searchRegex, 'gi');
      // console.log(regexRules.match('a'));
      const vue = this;
      if (this.search.length > 0) {
        return this.relics.filter((relic) => {
          if (relic.name.match(regexRules)) {
            // console.log(card);
            // console.log(vue.character);

            // filter out other champ cards - true / false
            // show all cards where character is undefined (homepage)
            if (!vue.allCharacterSearch && vue.character !== undefined) {
              // console.log(vue.otherCardGroups);

              // show all cards in non-character groups (colorless..)
              for (let d = 0; d < vue.otherCardGroups.length; d++) {
                // console.log('test');

                if (relic.character === vue.otherCardGroups[d]) {
                  // console.log(vue.otherCardGroups[d] + ' card');
                  return relic;
                }
              }

              // show only this character relics
              if (relic.character === vue.character) {
                // console.log(relic.name + ' is not the character ' + vue.character);
                return relic;
              }
            } else {
              return relic;
            }
          }
        });
      }
      return '';
    },
  },
  methods: {
    addRelicToDeck(name) {
      this.$emit('addRelicInfo', name);
    },
    searchData(searchData) {
      this.search = searchData;
    },
    itemPureName(name) {
      return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase();
    },
    openItemModal(itemName, type = 'relics') {
      // console.log('open modal');
      if (this.$route.name === 'sls-home') {

        const name = this.itemPureName(itemName);
        this.singleItemNameForModal = name;
        this.singleItemTypeForModal = type;
      }
    },
    closeCardModal() {
      // console.log('hi');
      this.singleItemNameForModal = '';
    },
  },
};
</script>

<style lang="scss" scoped>

.relics {
  max-width: 1280px;
  margin: auto;
  padding: 0 0 100px;
}

.card-type-block {
  padding: 50px 0 50px;
}

ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 40px;
  margin: 0 auto;

  @supports (display: grid){
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    // grid-gap: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 10px 25px;

    @media screen and (min-width: 630px) {
      grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
    @media screen and (min-width: 728px) {
      grid-gap: 30px;
      padding: 50px 50px;
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }

    @media screen and (min-width: 1280px) {
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    }
  }
}
li {
  width: 19%;
  // width: 100%;
  margin: 1% 0.5%;
  display: flex;
  align-items: flex-start;
  // overflow: hidden;
  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  // transition: scale 0.5s easing's easeOutCubic curve;
  @supports (display: grid){
    width: 100%;
    margin: 0;
  }

  transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
  // transition: scale 0.5s easing's easeOutCubic curve;
  &:hover {
    transform: scale(1.28, 1.28);
    transition: 0s;
    z-index: 100;
    // & ~ .base {
      // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
    // }
    // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
  }
  // &:hover {
  //   // transform: scale(1.28, 1.28);
  //   transition: 0s;
  //   z-index: 100;
  //   & ~ #sts-card-base-img {
  //     box-shadow: 0 0 20px 20px rgba(#000, 0.4);
  //   }
  //   // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
  // }
}

h2 {
  margin: 0 60px;
  text-align: left;
  font-size: 40px;
  text-shadow: 3px 3px 0 #000;
  text-transform: capitalize;
}

h3 {
  margin: 14px 60px 0;
  text-align: left;
  font-size: 22px;
  text-shadow: 3px 3px 0 #000;
  font-weight: 700;
  > span {
    opacity: 0.7;
    > span {
      margin: 0 3px;
    }
  }
}

.h2underline {
  width: 80px;
  text-align: left;
  margin-left: 80px;
}

.alignleft {
  text-align: left;
}

.search-filters {
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  opacity: 0;
  user-select: none;

  > .filter {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    > .tickbox {
      position: relative;
      width: 32px;
      height: 32px;
      margin-right: 4px;
      .check {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 50%;
        left:50%;
        transform: translate(-50%, -50%);
      }
    }
    > .label {
    }
    .input {
      position: absolute;
      top: -10000px;
    }
  }
  &-active {
    opacity: 1 !important;
  }
}

// animations

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.relic-modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}


.card-animation-leave-active,
.card-animation-enter-active {
  transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card-animation-enter {
  transform: translateY(100%);
  // z-index: 3000;
}
.card-animation-leave-to {
  transform: translateY(0);
  // z-index: 2900;
}

.modal-enter-active, .modal-leave-active {
  transition: .3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateY(100vh)
}

</style>

